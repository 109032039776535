export const LANDING = '/'
export const NEWS = '/news'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const HOME = '/home'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const ADMIN = '/admin'
export const ADMIN_DETAILS = '/admin/:id'
export const GAMES = '/games'
export const GAME = '/games/:id'
export const RESULTS = '/results'
export const RESULT = '/results/:id'
export const RANKING = '/ranking'
export const PLAYER = '/players/:id'
export const LIVE_GAMES = '/live-games'
export const LIVE_GAME = '/live-games/:id'
export const TEAM = '/team/:id'